import { ref, set } from "firebase/database";
import { dbApp2 } from "../helper/firebaseConfig";
import { convertFormateTimestamp, convertFormateTimestamp2 } from "../helper/GlobalFunctions";

export const sendMessage = (params) => {
    const timeStamp = Date.now();
    const createdAt = convertFormateTimestamp2(timeStamp)

    set(ref(dbApp2, params.pickup_id+'/'+timeStamp), {
        message: params.message,
        sender: params.sender,
        createdAt: createdAt,
    });
}