import React, { useEffect } from 'react'
import { FixedTopComponent } from './FixedTopComponent'
import { useNavigate } from 'react-router-dom'

export const NavbarTop = (props) => {
    const nav = useNavigate();

  return (
    <FixedTopComponent>
        <div className='d-flex sticky-top'>
            {
                props.have_back &&
                <div className='col-1 cursor'>
                    <span className='fw-bold' onClick={ () => nav(-1)}>
                        <i className='fas fa-arrow-left'></i>
                    </span>
                </div>
            }
            {
                props.title &&
                <div className='col'>
                    <span className='fw-bold'>{props.title}</span>
                </div>
            }
            {
                props.header_right &&
                <div className='col text-end'>
                    {props.header_right}
                </div>
            }
        </div>
    </FixedTopComponent>
  )
}
