import firebase from 'firebase/compat/app';
import { getDatabase } from 'firebase/database';

const firebaseConfigChat = {
    apiKey: process.env.REACT_APP_FIREBASE_CHAT_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_CHAT_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_CHAT_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_CHAT_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_CHAT_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_CHAT_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_CHAT_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_CHAT_MEASUREMENT_ID
};

if ( firebase.apps.length === 0) {
    // Initialize Firebase
    const appChat = firebase.initializeApp(firebaseConfigChat);
}

const dbChat = getDatabase();

export { dbChat }