import React from 'react'

export const ChatBabelRight = (props) => {
  return (
    <>
       <div className="message-row sender align-items-start px-1 py-2">
        <div className="message-bubble">
            {props.text}
            <div 
                className='text-end'
                style={{ 
                    fontSize: 10,
                }}
            
            >{props.datex}</div>
        </div>
        <div>
            <img 
                width={50} height={50} className='rounded-circle card' 
                src={props.img} 
            />
        </div>
      </div>
    </>
  )
}
