import React from 'react'

export const FixedTopComponent = (props) => {
  return (
    <>
      <div className='fixed-top bg-light' >
          <div className='p-3'>
              {props.children}
          </div>
      </div>
      <div style={{ height: 82}}>
        
      </div>
    </>
  )
}
