import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import MapPage from './screens/MapPage'
import Test from './screens/Test'
import TrackingPage from './screens/TrackingPage'
import { HomePage } from './screens/HomePage'
import { ChatPage } from './screens/ChatPage'
import { ChatDummyPage } from './screens/ChatDummyPage'
import NotFoundPage from './screens/NotFoundPage'

export const RoutesMain = () => {
  return (
    <BrowserRouter>
        <Routes>
          <Route 
            path='*'
            element={
              <NotFoundPage />
            }
          />
          <Route 
            path='/'
            element={
              <HomePage />
            }
          />
          <Route 
            path='/testx'
            element={
              <Test />
            }
          />
          <Route 
            path='/test/:origin/:empl_code'
            element={
              <Test />
            }
          />
          <Route 
            path='/tracking/:origin_code/:empl_code'
            element={
              <TrackingPage />
            }
          />
          <Route 
            path='/chat'
            element={
              <ChatPage />
            }
          />

          <Route 
            path='/chat_test'
            element={
              <ChatDummyPage />
            }
          />
        </Routes>
    </BrowserRouter>
  )
}
