import React from 'react'

export const ChatBabelLeft = (props) => {
  return (
    <>
        <div className="message-row receiver align-items-start px-1 py-2 rounded">
            <div>
                <img 
                    width={50} height={50} className='rounded-circle card' 
                    src={props.img} 
                />
            </div>
            <div className="message-bubble">
                {props.text}

                <div 
                    style={{ 
                        fontSize: 10,
                    }}
                
                >{props.datex}</div>
            </div>
        </div>
    </>
  )
}
