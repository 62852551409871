import React, { useEffect, useState } from 'react'
import { NavbarTop } from '../components/NavbarTop'
import { FixedBottomComponent } from '../components/FixedBottomComponent'
import { useLocation, useParams, useSearchParams } from 'react-router-dom'
import { ChatBabelRight } from '../components/Chat/ChatBabelRight'
import { ChatBabelLeft } from '../components/Chat/ChatBabelLeft'
import { SugestionChatCustomer } from '../data/GlobalData'
import { onValue, orderByChild, query, ref } from 'firebase/database'
import { dbChat } from '../helper/firebaseConfigChat'
import { dbApp2 } from '../helper/firebaseConfig'
import { sendMessage } from '../api/ChatPageApi'

export const ChatDummyPage = () => {
    const { state } = useLocation();
    
    const { param1, } = useParams();
    const driver_img = 'https://static.vecteezy.com/system/resources/previews/005/544/718/original/profile-icon-design-free-vector.jpg';


    const [sender_code, set_sender_code] = useState('');    
    const [message_input, set_message_input] = useState('');
    
    const [data_fire, set_data_fire] = useState([]);

    const pickup_id = '12345';

    useEffect( ( ) => {
        const url = new URL(window.location.href);
        const searchParams = new URLSearchParams(url.search);
        const sender_code_url = searchParams.get('sender_code');
        set_sender_code(sender_code_url)

        console.log("state", state);

        const dbChatRef = query(ref(dbApp2, pickup_id),  );
        
        onValue(dbChatRef, (snapshot) => {
            const data = snapshot.val();
            // console.log("duar x",data)

            set_data_fire([]);
            if (data !== null && data !== undefined) {

                const newPosts = Object.keys(data).map((key,i) => ({
                    id:key,
                    ...data[key]
                }));
                // console.log('data data', newPosts)
                
                set_data_fire(newPosts);
         
            } else {
                // no data
                set_data_fire([]);
            }

        })
    }, [])


    const [suggestions, setSuggestions] = useState([]);

    const handleInputChange = (event) => {
        const value = event.target.value;
        set_message_input(value);

        // Filter suggestions based on input value
        const filteredSuggestions = getSuggestions(value);
        setSuggestions(filteredSuggestions);
    };

    const handleSuggestionClick = (suggestion) => {
        set_message_input(suggestion);
        setSuggestions([]);
    };

    const getSuggestions = (value) => {
        // Define your list of options
        const options = SugestionChatCustomer;

        // Filter options based on input value
        const filteredOptions = options.filter((option) =>
        option.toLowerCase().startsWith(value.toLowerCase())
        );

        return filteredOptions;
    };

    const handleSend = async () => {
        try {
            const res = await sendMessage({
                pickup_id: pickup_id,
                message: message_input,
                sender: sender_code,
            });

            set_message_input('');

        } catch (error) {
            console.log('error send', error);
        }
    }

    

  return (
    <div>
        <NavbarTop 
            have_back={true}
            title={
                <>
                    <div className='d-flex'>
                        <div className='me-3'>
                            <img 
                                width={50} height={50} className='rounded-circle card' 
                                src={driver_img} 
                            />
                        </div>
                        <div className=''>
                            Rinaldy Ramadhan
                        </div>
                    </div>
                </>
            }
        />
        <div className='container-fluid px-3' style={{ paddingBottom: 82,}}>
            <div className="chat-container">
                {
                    data_fire.map( (v,i) => {
                        if ( v.sender === sender_code) {
                            return (
                                < >
                                    <ChatBabelRight 
                                        img={driver_img}
                                        text={v.message}
                                        datex={v.createdAt}
                                    />
                                </>
                            )
                        } else {
                            return (
                                < >
                                    <ChatBabelLeft 
                                        img={driver_img}
                                        text={v.message}
                                        datex={v.createdAt}
                                    />
                                </>
                            )
                        }
                    })
                }
            </div>
        </div>
        <FixedBottomComponent>
            <div className='row p-3 bg-light'>
                {suggestions.length > 0 && (
                    
                    <ul className="list-group mb-3">
                         {suggestions.map((suggestion) => (
                        <li
                        className="list-group-item"
                        key={suggestion}
                        onClick={() => handleSuggestionClick(suggestion)}
                        >
                        {suggestion}
                        </li>
                    ))}
                    </ul>
                )}
                <div className='col'>
                    <input 
                        value={message_input}
                        className='form-control p-2'
                        onChange={handleInputChange}
                        placeholder='Type something to driver'
                    />
                </div>
                {
                    message_input && 
                    <button 
                        className='col-2 col-md-1 btn btn-primary'
                        onClick={handleSend}
                    >
                        <i className='fas fa-arrow-right'></i>
                    </button>
                }
            </div>
        </FixedBottomComponent>
    </div>
  )
}
