import React, { useEffect, useRef, useState } from "react";
import { GoogleMap, DirectionsService, DirectionsRenderer, LoadScript, useJsApiLoader, Marker  } from "@react-google-maps/api";
import delivery_bike from '../assets/icons/delivery-bike.png'
import destination_icon from '../assets/icons/destination-icon.png'
import { getObjectCoordinates } from "../helper/GlobalFunctions";

const containerStyle = {
    width: '100%',
    height: '70vh'
};

  
const MyMapComponent = (props) => {
    const [directions, setDirections] = useState(null);
    const count = useRef(0);
    const [response, setResponse] = React.useState(null);
    // const [origin, setOrigin] = React.useState({ lat: -6.247516666666667, lng: 106.92256166666667 }); // TIKI PEMUDA
    // const [destination, setDestination] = React.useState({ lat: -6.2504406868243745, lng: 106.908746547997 }); // alfamart TIKI PEMUDA
    const [center_x, set_center_x] = React.useState(props.center_x); // TIKI PEMUDA
    const [origin, setOrigin] = React.useState(props.origin); // TIKI PEMUDA
    const [destination, setDestination] = React.useState(props.destination); // alfamart TIKI PEMUDA

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_SECRET_NAME
    })

    const iconOrigin = {
        url: delivery_bike,
        // scaledSize: new window.google.maps.Size(50, 50),
        // origin: new window.google.maps.Point(0, 0),
        // anchor: new window.google.maps.Point(25, 25),
        scaledSize: { width: 50, height: 50 },
        origin: { x: 0, y: 0 },
        anchor: { x: 25, y: 25 },
    };
    
    const iconDestination = {
        url: destination_icon,
        // scaledSize: new window.google.maps.Size(50, 50),
        // origin: new window.google.maps.Point(0, 0),
        // anchor: new window.google.maps.Point(25, 25),
        scaledSize: { width: 50, height: 50 },
        origin: { x: 0, y: 0 },
        anchor: { x: 25, y: 25 },
    };

  const directionsCallback = (res) => {
    if (res.status === "OK" && count.current === 0) {
        count.current++;
        console.count();
        setDirections(res);
        console.log(' directionsCallback respon ok',res);
    } else {
        console.log('directionsCallback ada yang salah',res);
    }
  };

  const [map, setMap] = React.useState(null)

  const onLoad = React.useCallback(function callback(map) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    const bounds = new window.google.maps.LatLngBounds(origin);
    map.fitBounds(bounds);
    console.log('onload')

    setMap(map)
  }, [])

  const onUnmount = React.useCallback(function callback(map) {
    console.log('onUnmount')
    setMap(null)
  }, [])

  const handleProsesChange = (orig, dest) => {
    const directionsService = new window.google.maps.DirectionsService();
    directionsService.route(
      {
        origin: orig?orig:origin,
        destination: destination,
        travelMode: "DRIVING",
      },
      (res, status) => {
        if (status === "OK") {
          console.log(" handleProsesChangeDirections ", res);
          setDirections(res);

        } else {
          console.log("Directions request failed due to " + status);
        }
      }
    );
  };

  useEffect( () => {
    setOrigin(props.origin);
    if ( directions ){
        handleProsesChange(props.origin)
    }
  }, [props.origin])

  if (!isLoaded) {
    return <div>Loading...</div>;
  }


return isLoaded ? (
    <>
        <h1>Tracking Driver App TIKI </h1>
        <GoogleMap
            mapContainerStyle={containerStyle}
            center={center_x}
            zoom={18}
            onLoad={onLoad}
            onUnmount={onUnmount}
        >
            
            <DirectionsService
                options={{
                    destination: destination,
                    origin: origin,
                    travelMode: "DRIVING",
                    // suppressMarkers: true, // Hide default markers
                }}
                callback={directionsCallback}
            />

            
            { directions  &&  
                <>
                    <DirectionsRenderer 
                        directions={directions} 
                        options={{
                            // travelMode: "DRIVING",
                            suppressMarkers: true, // Hide default markers
                        }}
                    />
                    <Marker
                        position={origin}
                        icon={iconOrigin}
                    />
                    <Marker
                        position={destination}
                        // icon={iconDestination}
                    />
                </>
            }

            
        </GoogleMap>

        {/* <div className='container mt-3'>
            <div className='row justify-content-center'>
                <div className='col-md-6'>
                    <div className='col'>
                        <label>Origin</label>
                        <input className='form-control' valuex={origin} onChange={ (e) => {
                            const obiji = getObjectCoordinates(e.currentTarget.value);
                            setOrigin(obiji)
                        }} />
                    </div>
                    <div className='col'>
                        <label>Destination</label>
                        <input className='form-control' valuex={destination} onChange={ (e) => {
                            const obiji = getObjectCoordinates(e.currentTarget.value);
                            setDestination(obiji)
                        }} />
                    </div>
                </div>
            </div>
            <div className='row justify-content-center'>
                <div className='col-md-6'>
                    <button className='btn btn-primary mt-5' onClick={handleProsesClick} >Process</button>
                </div>
            </div>
        </div> */}
    </>
) : <></>
};

export default MyMapComponent;
