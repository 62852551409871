export const getArrayCoordinates = (string_coordinate) => {
    // "-6.1938067169558995, 106.8965095208342"
    const coordinates = string_coordinate;
    const [latitude, longitude] = coordinates.split(",").map(parseFloat);
    const array = [latitude, longitude];
    return array;
}

export const getObjectCoordinates = (string_coordinate) => {
    // "-6.1938067169558995, 106.8965095208342"
    const coordinates = string_coordinate;
    const [lat, lng] = coordinates.split(",").map(parseFloat);
    const obj = { lat, lng };
    return obj;
}

export const convertFormateTimestamp = (timestampx) => {
    const timestamp = timestampx;
    const date = new Date(timestamp * 1000); // Convert Unix timestamp to milliseconds

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    console.log('formattedDate', formattedDate);

    return formattedDate;
}

export const convertFormateTimestamp2 = (timestampx) => {
    const timestamp = timestampx;
const date = new Date(timestamp);

const year = date.getFullYear();
const month = String(date.getMonth() + 1).padStart(2, '0');
const day = String(date.getDate()).padStart(2, '0');
const hours = String(date.getHours()).padStart(2, '0');
const minutes = String(date.getMinutes()).padStart(2, '0');
const seconds = String(date.getSeconds()).padStart(2, '0');

const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

    return formattedDate;
}