import React, { useEffect, useState } from 'react'
import MyMapComponent from '../components/MyMapComponent'

import { set, ref, onValue, query, limitToLast, get, child, orderByChild, orderByKey } from 'firebase/database'
import { db } from '../helper/firebaseConfig';
import { useParams } from 'react-router-dom';

const TrackingPage = () => {
    // PARAMS FROM URL
    const { origin_code, empl_code } = useParams();

    const [origin, setOrigin] = React.useState({ lat: 0, lng: 0 }); // TIKI PEMUDA
    const [center_x, set_center_x] = React.useState({ lat: -6.174918130401308, lng:106.82680401868227 }); // TIKI PEMUDA
    const [destination, setDestination] = React.useState({ lat: 0, lng: 0 }); // alfamart TIKI PEMUDA

    const [is_have_data, set_is_have_data] = useState(null);

    const [loading_screen, set_loading_screen] = useState(true);

    const [data_fire, set_data_fire] = useState([]);

    useEffect( () => {
        const origin = 'CGK01';
        // const empl_code = 'CGK0102199';
        const dbChatRef = query(ref(db, origin_code+'/'+empl_code), orderByChild("sys_created_on", 'asc'), );
        
        onValue(dbChatRef, (snapshot) => {
            const data = snapshot.val();
            console.log("duar x",data)

            set_data_fire([]);
            if (data !== null && data !== undefined) {
                setOrigin({
                    lat: parseFloat(data.lat),
                    lng: parseFloat(data.lng),
                })
    
                if ( center_x == null ) {
                    set_center_x({
                        lat: parseFloat(data.lat),
                        lng: parseFloat(data.lng),
                    })
                }
    
                setDestination({ 
                    lat: -6.250429006484551, 
                    lng: 106.90870280240563
                })

                const newPosts = Object.keys(data).map((key,i) => ({
                    id:i,
                    lat:data.lat,
                    lng:data.lng,
                    ...data[key]
                }));
                console.log('data data', newPosts)
                
                set_data_fire(newPosts);
                setTimeout( () => {
                }, 1000)
            } else {
                // no data
                set_data_fire([]);
            }

        })
    }, [])

    if ( origin_code && empl_code) {

        return (
            <div>
                {
                    data_fire.length > 0 ?
                    <>
                        <MyMapComponent center_x={center_x} origin={origin} destination={destination} />
                    </>
                    :
                    <div className='text-center'>
                    <h1>Oops Data Tidak Ditemukan</h1>
                </div>
                }
            </div>
        )
        
    } 

}

export default TrackingPage