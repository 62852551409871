import firebase from 'firebase/compat/app';
import { getDatabase } from 'firebase/database';

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

const firebaseConfigApp2 = {
    apiKey: process.env.REACT_APP_FIREBASE_CHAT_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_CHAT_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_CHAT_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_CHAT_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_CHAT_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_CHAT_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_CHAT_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_CHAT_MEASUREMENT_ID
};



// if ( firebase.apps.length === 0) {
    // Initialize Firebase
    const app = firebase.initializeApp(firebaseConfig);

    // Initialize the second Firebase app
    const app2 = firebase.initializeApp(firebaseConfigApp2, 'app2');
// }

const db = getDatabase();
const dbApp2 = getDatabase(app2); // Database of the second Firebase app

export { db, dbApp2 }