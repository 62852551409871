import React, { useState } from 'react'
import { Link, Route, Routes, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { FixedBottomComponent } from '../components/FixedBottomComponent';
import { NavbarTop } from '../components/NavbarTop';
import Colors from '../helper/Colors';

const containerStyle = {
    width: '100%',
    height: '70vh'
};

const Test = () => {
    const nav = useNavigate();
    const driver_img = 'https://static.vecteezy.com/system/resources/previews/005/544/718/original/profile-icon-design-free-vector.jpg';
    const [is_refresh, set_is_refresh] = useState(false);
    let [searchParams, setSearchParams] = useSearchParams();
    let user = searchParams.get("empl_code");

    const {origin, empl_code} = useParams() 

  return (
    <>
            <NavbarTop 
                title="Live Tracking" 
                header_right={
                    <>
                        <span className='cursor' onClick={() => {
                                set_is_refresh(true)
                                setTimeout(() => {
                                    window.location.reload()
                                }, 1000);
                            }
                        }>
                        <i class={`fas fa-sync ${ is_refresh? 'fa-spin' : ''}`}></i>
                        </span>
                    </>
                }
            />
        <div className='container-fluid'>
            <div className='row'>
                <div>Test </div>
                <div>Origin Code: {origin}</div>
                <div>empl_code Code: {empl_code}</div>
            </div>
            
            <div
                style={containerStyle}
                className='border border-danger'
            >
            </div>
            <FixedBottomComponent>
                <div className="card card-body bg-white border cursor" 
                    style={{ borderTopLeftRadius: 50, borderTopRightRadius: 50,}}
                >
                    <span className="btnx text-center" 
                        data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample"
                    >
                        <span className='fw-bold' style={{ color: Colors.primary_blue_dark}}>Cek Detail</span>
                    </span>
                    <div className="collapse" id="collapseExample">
                        <div class="row gx-5">
                            <div class="col-12 col-md-4 d-flex">
                                <div className='me-3'>
                                    <img 
                                        width={50} height={50} className='rounded-circle card' 
                                        src={driver_img} 
                                    />
                                </div>
                                <div>
                                    <span className='fw-bold'>B 666 STN</span> <br></br>
                                    <span className='fw-normal'>Rinaldy Ramadhan</span> |
                                    <span className='fw-normal'> Honda Beat</span> <br></br>
                                </div>
                            </div>
                        </div>
                        <hr></hr>
                        <div class="row gx-5">
                            <div class="col">
                                <span className=''>Pengirim</span> <br></br>
                                <span className='fw-bold'>Tambun, Bekasi</span>
                            </div>
                            <div class="col">
                                <span className=''>Penerima</span> <br></br>
                                <span className='fw-bold'>Klender, Jakarta Timur</span>
                            </div>
                        </div>
                        <hr></hr>
                        <div class="row gx-5">
                            <div class="col">
                                <div className='row'>
                                    <div className='col col-sm-3 text-center'>
                                        <div onClick={
                                            () => {
                                                nav('/chat', {
                                                    state: {
                                                        driver_img: driver_img
                                                    }
                                                })
                                            }
                                        }>
                                            <span className=''>Chat</span> <br></br>
                                            <span className='fw-bold h3'>
                                                <i class={`fas fa-comment `} ></i>
                                            </span>
                                        </div>
                                    </div>
                                    <div className='col col-sm-3 text-center'>
                                        <span className=''>Phone</span> <br></br>
                                        <span className='fw-bold h3'>
                                            <i class={`fas fa-phone `} ></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                            </div>
                        </div>
                    </div>
                </div>
            </FixedBottomComponent>

        </div>
    </>
  )
}

export default Test