export default {
    primary: '#C2185B',
    accent: '#FFC107',
    primary_blue: '#05257f',
    primary_blue_2: '#0632ad',
    primary_blue_light: '#14A1FF',
    primary_blue_dark: '#303F9F',
    primary_blue_v2: '#0044AB',
    blue_soft: '#1B74A3',
    black: '#000000',
    darkgray: '#a9a9a9',
    dimgrey: '#696969',
    gray: '#808080',
    white: '#ffffff',
    orange: '#ff8c00',
    red_light: '#dc143c',
    sandybrown: '#f4a460',
    successColor :  '#6FCF97',
    warningColor : '#F2C94C',
    dangerColor : '#EB5757',

};