import React from 'react';
import logo from './logo.svg';
import './App.css';
import { RoutesMain } from './RoutesMain';

function App() {
  const from = { lat: 40.712776, lng: -74.005974 }; // New York City
  const to = { lat: 51.507351, lng: -0.127758 }; // London


  return (
      <RoutesMain />
  );
}

export default App;
